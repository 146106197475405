import axios from 'axios';
import { requestHasErrored, requestInProcess, updateItemId } from './commonAction';
import { timeDiff, getNewToken } from '../services/common';
var JSONbigString = require('json-bigint')({ "storeAsString": true });

export function getBranchList(url) {
  return async (dispatch) => {
    dispatch(requestInProcess(true));
    var token = sessionStorage.getItem('tokenInfo');
    //always check token validation
    if (timeDiff()) {
      await getNewToken(token).then(function (res) {
        token = res.data.data.access_token;
      });
    }

    try {
      const response = await axios.get(url, { 'headers': { "Authorization": `Bearer ${token}` }, transformResponse: data => JSONbigString.parse(data) });
      if (response.status === 200) {
        // console.log(response);
        const arr = response.data.data.branches;
        dispatch(requestInProcess(false));
        dispatch(getBranchListSuccess(arr));
      }
      return response;
    }
    catch (error) {
      dispatch(requestInProcess(false));
      dispatch(requestHasErrored(true));
      // console.log(error);
      return Promise.reject(error.response);
    }
  };
}

export function getBranchListSuccess(list) {
  return {
    type: 'GET_BRANCH_LIST_SUCCESS',
    list
  };
}

export function emptyBranchList() {
  return {
    type: 'EMPTY_BRANCH_LIST'
  };
}

export function getBranchListForDropdown(url) {
  return async (dispatch) => {
    dispatch(requestInProcess(true));
    var token = sessionStorage.getItem('tokenInfo');
    //always check token validation
    if (timeDiff()) {
      await getNewToken(token).then(function (res) {
        token = res.data.data.access_token;
      });
    }

    try {
      const response = await axios.get(url, { 'headers': { "Authorization": `Bearer ${token}` }, transformResponse: data => JSONbigString.parse(data) });
      if (response.status === 200) {
        // console.log(response);
        const arr = response.data.data.branches;
        dispatch(requestInProcess(false));
        dispatch(getBranchListForDropdownSuccess(arr));
      }
      return response;
    }
    catch (error) {
      dispatch(requestInProcess(false));
      dispatch(requestHasErrored(true));
      // console.log(error);
      return Promise.reject(error.response);
    }
  };
}

export function getBranchListForDropdownSuccess(list) {
  return {
    type: 'GET_BRANCH_LIST_FOR_DROPDOWN_SUCCESS',
    list
  };
}

export function getBranchDetails(url) {
  return async (dispatch) => {
    dispatch(requestInProcess(true));
    var token = sessionStorage.getItem('tokenInfo');
    //always check token validation
    if (timeDiff()) {
      await getNewToken(token).then(function (res) {
        token = res.data.data.access_token;
      });
    }

    try {
      const response = await axios.get(url, { 'headers': { "Authorization": `Bearer ${token}` }, transformResponse: data => JSONbigString.parse(data) });
      if (response.status === 200) {
        // console.log(response);
        dispatch(requestInProcess(false));
        dispatch(getBranchDetailSuccess(response.data.data));
      }
      return response;
    }
    catch (error) {
      dispatch(requestInProcess(false));
      dispatch(requestHasErrored(true));
      // console.log(error);
      return Promise.reject(error.response);
    }
  };
}

export function getBranchDetailSuccess(branch) {
  return {
    type: 'GET_BRANCH_DETAIL_SUCCESS',
    branch
  };
}

export function createBranch(url, data) {
  return async (dispatch) => {
    dispatch(requestInProcess(true));
    var token = sessionStorage.getItem('tokenInfo');
    //always check token validation
    if (timeDiff()) {
      await getNewToken(token).then(function (res) {
        token = res.data.data.access_token;
      });
    }

    try {
      const response = await axios.post(url, data, { 'headers': { "Authorization": `Bearer ${token}` }, transformResponse: data => JSONbigString.parse(data) });
      if (response.data.message === "Created") {
        // console.log(response);
        dispatch(requestInProcess(false));
        dispatch(addBranchSuccess(response.data.data));
      }
      return true;
    }
    catch (error) {
      dispatch(requestInProcess(false));
      dispatch(requestHasErrored(true));
      console.log(error);
      return false;
    }
  };
}

export function cloneBranch(url, branch) {
  return async (dispatch) => {
    dispatch(requestInProcess(true));
    var token = sessionStorage.getItem('tokenInfo');
    //always check token validation
    if (timeDiff()) {
      await getNewToken(token).then(function (res) {
        token = res.data.data.access_token;
      });
    }

    try {
      const response = await axios.post(url, {}, { 'headers': { "Authorization": `Bearer ${token}` }, transformResponse: data => JSONbigString.parse(data) });
      if (response.data.message === "Created" || response.status === 200) {
        // console.log(response);
        if (response.data.id)
          branch.id = response.data.id;
        else
          branch.id = Math.random();
        dispatch(requestInProcess(false));
        dispatch(addBranchSuccess(branch));
      }
      return true;
    }
    catch (error) {
      dispatch(requestInProcess(false));
      dispatch(requestHasErrored(true));
      console.log(error);
      return false;
    }
  };
}

export function addBranchSuccess(branch) {
  return {
    type: 'ADD_BRANCH_SUCCESS',
    branch
  };
}

const URL_LAST_PART_WORDS = ['configuration', 'grouping', 'categories', 'subscription'];

export function updateBranchDetails(url, data) {
  return async (dispatch) => {
    dispatch(requestInProcess(true));
    var token = sessionStorage.getItem('tokenInfo');
    //always check token validation
    if (timeDiff()) {
      await getNewToken(token).then(function (res) {
        token = res.data.data.access_token;
      });
    }

    try {
      const urlpieces = url.split('/');
      const lastpartofurl = urlpieces[urlpieces.length - 1];
      const response = URL_LAST_PART_WORDS.includes(lastpartofurl) || urlpieces.includes('voucher') ?
        await axios.put(url, data, { 'headers': { "Authorization": `Bearer ${token}` }, transformResponse: data => JSONbigString.parse(data) }) :
        await axios.post(url, data, { 'headers': { "Authorization": `Bearer ${token}` }, transformResponse: data => JSONbigString.parse(data) });
      if (response.status === 200) {
        // console.log(response);
        dispatch(requestInProcess(false));
      }
      return response;
    }
    catch (error) {
      dispatch(requestInProcess(false));
      dispatch(requestHasErrored(true));
      // console.log(error);
      return Promise.reject(error.response);
    }
  };
}

export function handleRemove(url, id) {
  return async (dispatch) => {
    dispatch(requestInProcess(true));
    var token = sessionStorage.getItem('tokenInfo');
    //always check token validation
    if (timeDiff()) {
      await getNewToken(token).then(function (res) {
        token = res.data.data.access_token;
      });
    }

    try {
      const response = await axios.delete(url, { 'headers': { "Authorization": `Bearer ${token}` } });
      if (response.status === 200) {
        // console.log(response);
        dispatch(deleteBranchSuccess(id));
        dispatch(requestInProcess(false));
      }
      return response;
    }
    catch (error) {
      dispatch(requestInProcess(false));
      dispatch(requestHasErrored(true));
      // console.log(error);
      return Promise.reject(error.response);
    }
  };
}

export function deleteBranchSuccess(branchId) {
  return {
    type: 'DELETE_BRANCH_SUCCESS',
    branchId
  };
}

export function generateQRcode(url) {
  return async (dispatch) => {
    dispatch(requestInProcess(true));
    var token = sessionStorage.getItem('tokenInfo');
    //always check token validation
    if (timeDiff()) {
      await getNewToken(token).then(function (res) {
        token = res.data.data.access_token;
      });
    }

    try {
      const response = await axios.post(url, {}, { 'headers': { "Authorization": `Bearer ${token}` } });
      if (response.status === 200) {
        // console.log(response);
        dispatch(requestInProcess(false));
      }
      return response;
    }
    catch (error) {
      dispatch(requestInProcess(false));
      dispatch(requestHasErrored(true));
      // console.log(error);
      return Promise.reject(error.response);
    }
  };
}

export function getTotalStats(url) {
  return async (dispatch) => {
    dispatch(requestInProcess(true));
    var token = sessionStorage.getItem('tokenInfo');
    //always check token validation
    if (timeDiff()) {
      await getNewToken(token).then(function (res) {
        token = res.data.data.access_token;
      });
    }

    try {
      const response = await axios.get(url, { 'headers': { "Authorization": `Bearer ${token}` }, transformResponse: data => JSONbigString.parse(data) });
      if (response.status === 200) {
        // console.log(response);
        dispatch(requestInProcess(false));
      }
      return response;
    }
    catch (error) {
      dispatch(requestInProcess(false));
      dispatch(requestHasErrored(true));
      // console.log(error);
      return Promise.reject(error.response);
    }
  };
}

export function removeObject(url) {
  return async (dispatch) => {
    dispatch(requestInProcess(true));
    var token = sessionStorage.getItem('tokenInfo');
    //always check token validation
    if (timeDiff()) {
      await getNewToken(token).then(function (res) {
        token = res.data.data.access_token;
      });
    }

    try {
      const response = await axios.delete(url, { 'headers': { "Authorization": `Bearer ${token}` } });
      if (response.status === 200) {
        // console.log(response);
        dispatch(requestInProcess(false));
      }
      return response;
    }
    catch (error) {
      dispatch(requestInProcess(false));
      dispatch(requestHasErrored(true));
      // console.log(error);
      return Promise.reject(error.response);
    }
  };
}

export function getCorpUserList(url) {
  return async (dispatch) => {
    dispatch(requestInProcess(true));
    var token = sessionStorage.getItem('tokenInfo');
    //always check token validation
    if (timeDiff()) {
      await getNewToken(token).then(function (res) {
        token = res.data.data.access_token;
      });
    }

    try {
      const response = await axios.get(url, { 'headers': { "Authorization": `Bearer ${token}` }, transformResponse: data => JSONbigString.parse(data) });
      if (response.status === 200) {
        // console.log(response);
        dispatch(requestInProcess(false));
        dispatch(getCorpUserListSuccess(response.data.data));
      }
      return response;
    }
    catch (error) {
      dispatch(requestInProcess(false));
      dispatch(requestHasErrored(true));
      // console.log(error);
      return Promise.reject(error.response);
    }
  };
}

export function getCorpUserListSuccess(list) {
  return {
    type: 'GET_CORPORATE_USER_LIST',
    list
  };
}

export function generateUrl(url) {
  return async (dispatch) => {
    dispatch(requestInProcess(true));
    var token = sessionStorage.getItem('tokenInfo');
    //always check token validation
    if (timeDiff()) {
      await getNewToken(token).then(function (res) {
        token = res.data.data.access_token;
      });
    }

    try {
      const response = await axios.post(url, {}, { 'headers': { "Authorization": `Bearer ${token}` } });
      if (response.status === 200) {
        // console.log(response);
        dispatch(requestInProcess(false));
      }
      return response;
    }
    catch (error) {
      dispatch(requestInProcess(false));
      dispatch(requestHasErrored(true));
      console.log(error);
      return false;
    }
  };
}

export function addCorpUser(url, data) {
  return async (dispatch) => {
    dispatch(requestInProcess(true));
    var token = sessionStorage.getItem('tokenInfo');
    //always check token validation
    if (timeDiff()) {
      await getNewToken(token).then(function (res) {
        token = res.data.data.access_token;
      });
    }

    try {
      const response = await axios.post(url, {}, { 'headers': { "Authorization": `Bearer ${token}` }, transformResponse: data => JSONbigString.parse(data) });
      if (response.data.message === "Success") {
        // console.log(response);
        dispatch(requestInProcess(false));
        dispatch(addCorpUserSuccess(data));
      }
      return response;
    }
    catch (error) {
      dispatch(requestInProcess(false));
      dispatch(requestHasErrored(true));
      console.log(error);
      return false;
    }
  };
}

export function addCorpUserSuccess(user) {
  return {
    type: 'ADD_CORPORATE_USER',
    user
  };
}

export function deleteUser(url, id) {
  return async (dispatch) => {
    dispatch(requestInProcess(true));
    var token = sessionStorage.getItem('tokenInfo');
    //always check token validation
    if (timeDiff()) {
      await getNewToken(token).then(function (res) {
        token = res.data.data.access_token;
      });
    }

    try {
      const response = await axios.delete(url, { 'headers': { "Authorization": `Bearer ${token}` } });
      if (response.status === 200) {
        // console.log(response);
        dispatch(deleteUserSuccess(id));
        dispatch(requestInProcess(false));
      }
      return response;
    }
    catch (error) {
      dispatch(requestInProcess(false));
      dispatch(requestHasErrored(true));
      // console.log(error);
      return Promise.reject(error.response);
    }
  };
}

export function deleteUserSuccess(userId) {
  return {
    type: 'DELETE_CORPORATE_USER',
    userId
  };
}

export function addItem(url, data) {
  return async (dispatch) => {
    dispatch(requestInProcess(true));
    var token = sessionStorage.getItem('tokenInfo');
    //always check token validation
    if (timeDiff()) {
      await getNewToken(token).then(function (res) {
        token = res.data.data.access_token;
      });
    }

    try {
      const response = await axios.post(url, data, { 'headers': { "Authorization": `Bearer ${token}` }, transformResponse: data => JSONbigString.parse(data) });
      if (response.status === 200) {
        // console.log(response);
        dispatch(requestInProcess(false));
      }
      return response;
    }
    catch (error) {
      dispatch(requestInProcess(false));
      dispatch(requestHasErrored(true));
      // console.log(error);
      return Promise.reject(error.response);
    }
  };
}

export function deleteItem(url) {
  return async (dispatch) => {
    dispatch(requestInProcess(true));
    var token = sessionStorage.getItem('tokenInfo');
    //always check token validation
    if (timeDiff()) {
      await getNewToken(token).then(function (res) {
        token = res.data.data.access_token;
      });
    }

    try {
      const response = await axios.delete(url, { 'headers': { "Authorization": `Bearer ${token}` } });
      if (response.data.message === "Success") {
        // console.log(response);
        dispatch(requestInProcess(false));
      }
      return response;
    }
    catch (error) {
      dispatch(requestInProcess(false));
      dispatch(requestHasErrored(true));
      // console.log(error);
      return Promise.reject(error.response);
    }
  };
}

export function getStarsList(url) {
  return async (dispatch) => {
    dispatch(requestInProcess(true));
    var token = sessionStorage.getItem('tokenInfo');
    //always check token validation
    if (timeDiff()) {
      await getNewToken(token).then(function (res) {
        token = res.data.data.access_token;
      });
    }

    try {
      const response = await axios.get(url, { 'headers': { "Authorization": `Bearer ${token}` }, transformResponse: data => JSONbigString.parse(data) });
      if (response.status === 200) {
        // console.log(response);
        const arr = response.data.data;
        var arrLength = arr.length;
        var lastItemId = arrLength > 0 ? typeof arr[arrLength - 1].id === 'number' ? arr[arrLength - 1].id.toString() : arr[arrLength - 1].id : '';
        dispatch(updateItemId(lastItemId));
        dispatch(getStarsListSuccess(arr));
        dispatch(requestInProcess(false));
      }
      return response;
    }
    catch (error) {
      dispatch(requestInProcess(false));
      dispatch(requestHasErrored(true));
      // console.log(error);
      return Promise.reject(error.response);
    }
  };
}

export function getStarsListSuccess(list) {
  return {
    type: 'GET_STARS_LIST',
    list
  };
}

export function emptyStarsList() {
  return {
    type: 'EMPTY_STARS_LIST'
  };
}

export function getDropdownBranchList(url) {
  return async (dispatch) => {
    dispatch(requestInProcess(true));
    var token = sessionStorage.getItem('tokenInfo');
    //always check token validation
    if (timeDiff()) {
      await getNewToken(token).then(function (res) {
        token = res.data.data.access_token;
      });
    }

    try {
      const response = await axios.get(url, { 'headers': { "Authorization": `Bearer ${token}` }, transformResponse: data => JSONbigString.parse(data) });
      if (response.status === 200) {
        // console.log(response);
        const arr = response.data.data.branches;
        let branchListOptions = [];
        arr.forEach(element => {
          const obj = {
            key: element.id,
            value: element.id,
            text: element.name
          }
          branchListOptions.push(obj);
        });
        dispatch(requestInProcess(false));
        dispatch(getDropdownBranchListSuccess(branchListOptions));
      }
      return response;
    }
    catch (error) {
      dispatch(requestInProcess(false));
      dispatch(requestHasErrored(true));
      // console.log(error);
      return Promise.reject(error.response);
    }
  };
}

export function getDropdownBranchListSuccess(list) {
  return {
    type: 'GET_DROPDOWN_BRANCH_LIST_SUCCESS',
    list
  };
}

export function dynamicGetMethod(url) {
  return async (dispatch) => {
    dispatch(requestInProcess(true));
    var token = sessionStorage.getItem('tokenInfo');
    //always check token validation
    if (timeDiff()) {
      await getNewToken(token).then(function (res) {
        token = res.data.data.access_token;
      });
    }

    try {
      const response = await axios.get(url, { 'headers': { "Authorization": `Bearer ${token}` }, transformResponse: data => JSONbigString.parse(data) });
      if (response.status === 200) {
        // console.log(response);
        dispatch(requestInProcess(false));
      }
      return response;
    }
    catch (error) {
      dispatch(requestInProcess(false));
      dispatch(requestHasErrored(true));
      // console.log(error);
      return Promise.reject(error.response);
    }
  };
}
