import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getBranchDetails, updateBranchDetails, handleRemove,
  getBranchDetailSuccess, generateQRcode, removeObject,
  addItem, deleteItem
} from '../../actions/branchAction';
import { handleGetMethod } from '../../actions/commonAction';
import { setCoordinates } from '../../actions/mapAction';
import { getServiceList, emptyServiceList } from '../../actions/serviceAction';
import { getCategoryList } from '../../actions/categoryAction';
import { Grid, Tab, Button, Header, Image, Dimmer, Loader, Popup, Modal, Input, Icon, TextArea } from 'semantic-ui-react';
import ContractData from '../stateless/ContractData';
import BannerData from '../stateless/BannerData';
import SocialNetData from '../stateless/SocialNetData';
import WorkHourData from '../stateless/WorkHourData';
import WhatsApp from '../stateless/WhatsApp';
import QRcodeData from '../stateless/QRcodeData';
import MenuPanel from "./MenuPanel";
import ServiceData from "../stateless/ServiceData";
import Message from '../stateless/Message';
import CertificateData from '../stateless/CertificateData';
import SecurityData from '../stateless/SecurityData';
// import CashbackData from '../stateless/CashbackData';
import GradationData from '../stateless/GradationData';
import CrossMarketingData from '../stateless/CrossMarketingData';
import Ambassador from './Ambassador';
import ProfileData from '../stateless/ProfileData';
import _ from 'lodash';
import '../../assets/css/branch.css';
import VoucherData from '../stateless/VoucherData';

//style for Popup
const popupStyle = {
  borderRadius: 5,
  opacity: 0.7,
  background: 'red'
}

class Branch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      branch: {},
      cityId: sessionStorage.getItem('cityId') ? sessionStorage.getItem('cityId') : '1',
      name: '',
      description: '',
      editDetailsOn: false,
      enabled: '',
      branchIcon: '',
      editIsOn: false,
      //for contract
      rule: {},
      legal_name: '',
      commission_percentage: '',
      cashback_percentage: '',
      extra_commission_percentage: '',
      certificate_cashback_percentage: '',
      order_min_amount: '',
      order_max_amount: '',
      bank_name: '',
      bik: '',
      bin: '',
      iik: '',
      editGroupId: false,
      groupId: '',
      editCategory: false,
      categories: [],
      categoryId: '',
      categoryListOptions: [],
      subCategOptions: [],
      subCategIdList: [],
      //for banner
      bannerList: [],
      banner_id: '',
      file: {},
      //for social
      website: '',
      siteEditIsOn: false,
      socialNetList: [],
      network_type: '',
      network_url: '',
      network_index: '',
      //for work-hours
      workHourList: [],
      phoneList: [],
      phone: '',
      phone1: '',
      phone2: '',
      phone3: '',
      phoneIsValid: true,
      phoneOrder: 0,
      address: '',
      lat: '',
      lon: '',
      startIsValid: true,
      endIsValid: true,
      startWorkHourDay: 0,
      endWorkHourDay: 0,
      lunchStartIsValid: true,
      lunchEndIsValid: true,
      startLunchHourDay: 0,
      endLunchHourDay: 0,
      //for Whatsapp
      whatsappPhoneNumber: '',
      whatsappProvider: '',
      whatsappEnabled: false,
      isBeta: false,
      isUsedForAllGroups: false,
      reportPhoneNumbers: [],
      whatsappUrl: '',
      whatsappMsgPositive: true,
      whatsappMsgHidden: true,
      whatsappMsgBody: '',
      whatsappCommercialText: '',
      whatsappHeaderText: '',
      whatsappHeaderTextEnabled: false,
      whatsappMailingLimit: 0,
      limit: 0,
      //for Modal
      iconModalOpen: false,
      socialNetModalOpen: false,
      editOrAdd: 'add',
      bannerModalOpen: false,
      //for QR code
      qr: '',
      directAvailable: false,
      extra_fields: [],
      createDirectExtraFieldIsOn: false,
      directCurrentCustomFieldText: "",
      direct_custom_field: "",
      directCustomFieldEditIsOn: false,
      //for MenuPanel
      menuAvailable: false,
      onlineCurrentCustomFieldText: "",
      online_custom_field: "",
      onlineCustomFieldEditIsOn: false,
      //for certificate
      certificateAvailable: false,
      certificateExpDate: '',
      editCertificateExpDate: false,
      //for corporate cashback
      cashbackIsSet: false,
      editCashback: false,
      cashback: '',
      share_url: '',
      //for gradation
      gradationAvailable: false,
      gradationCashbackList: [],
      newCashback: '',
      amount: '',
      //for elevated cashback
      elevatedCashbackAvailable: false,
      firstPurchase: '',
      //for private cashback
      privateCashbackAvailable: false,
      //for cash payment
      cashPaymentAvailable: false,
      cashExtraCommission: '',
      balance: '',
      topUpMsgVisible: false,
      //for auto payment
      autoPaymentEnabled: false,
      autoPaymentInterval: '',
      //for sms
      smsEnabled: false,
      smsLimit: '',
      //for Additional services
      serviceListOptions: [],
      serviceModalOpen: false,
      serviceIdList: [],
      helperServiceIdList: [],
      //for Subscription

      //for Message
      positive: true,
      hidden: true,
      messageTitle: '',
      messageBody: ''
    };

    this.onChange = this.onChange.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
  }

  componentDidMount() {
    const { branchId } = this.props.match.params;
    const { cityId } = this.state;
    this.props.getBranchDetails('/api/v1/branches/' + branchId + '/full')
      .then(result => {
        if (result) {
          if (result.status === 200) {
            this.props.getCategoryList('/api/v1/categories?city_id=' + cityId)
              .then(res => {
                if (res) {
                  if (res.status === 200) {
                    let categories = []; let categoryId = ''; let subCategIdList = [];
                    result.data.data.categories.forEach(category => {
                      const foundCategory = this.props.allcategoryList.find(x => x.id === category);
                      if (foundCategory) categories.push({ id: foundCategory.id, name: foundCategory.name, parentId: foundCategory.parent_id });
                      if (this.props.categoryList.find(x => x.id === category) !== undefined) {
                        categoryId = category;
                      } else {
                        subCategIdList.push(category);
                      }
                    });
                    this.setState({ categories, categoryId, subCategIdList }, function () {
                      const subCategList = this.props.allcategoryList.filter(x => x.parent_id === this.state.categoryId);
                      let subCategOptions = [];
                      subCategList.forEach(el => {
                        const obj = {
                          key: el.id,
                          value: el.id,
                          text: el.name
                        };
                        subCategOptions.push(obj);
                      });
                      this.setState({ subCategOptions });
                    });
                  }
                }
              });
          }
        }
      })
      .catch(error => {
        alert(error.data.message);
        this.props.history.push('/branches');
      });

    this.props.getServiceList('/api/v1/additionally')
      .then(res => {
        if (res) {
          // console.log(res);
          if (res.status === 200) {
            let serviceListOptions = [];
            res.data.data.forEach(element => {
              const obj = {
                key: element.id,
                value: element.id,
                text: element.name
              }
              serviceListOptions.push(obj);
            });
            this.setState({ serviceListOptions });
          }
        }
      }, function (error) {
        if (error) {
          this.setState({
            serviceListOptions: []
          }, function () {
            alert(error.data.message);
          });
        }
      }.bind(this));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.branch !== this.props.branch) {
      const { branch } = this.props;
      this.setState({
        branch,
        name: branch.name,
        description: branch.description,
        enabled: branch.enabled,
        branchIcon: branch.icon,
        //set contract details
        rule: branch.rule,
        legal_name: branch.rule ? branch.rule.legal_name : '',
        commission_percentage: branch.rule ? branch.rule.commission_percentage : '',
        cashback_percentage: branch.rule ? branch.rule.cashback_percentage : '',
        extra_commission_percentage: branch.rule ? branch.rule.extra_commission_percentage : '',
        certificate_cashback_percentage: branch.rule ? branch.rule.certificate_cashback_percentage : '',
        order_min_amount: branch.rule ? branch.rule.order_min_amount : '',
        order_max_amount: branch.rule ? branch.rule.order_max_amount : '',
        bank_name: branch.rule ? branch.rule.bank : '',
        bik: branch.rule ? branch.rule.bik : '',
        bin: branch.rule ? branch.rule.bin : '',
        iik: branch.rule ? branch.rule.iik : '',
        //set banner details
        bannerList: branch.banners,
        //set social details
        website: branch.site,
        socialNetList: branch.social_networks,
        //set work-hour details
        workHourList: branch.working_hours,
        phoneList: branch.phones,
        phone1: branch.phones.length >= 1 ? branch.phones[0] : '',
        phone2: branch.phones.length >= 2 ? branch.phones[1] : '',
        phone3: branch.phones.length >= 3 ? branch.phones[2] : '',
        address: branch.address,
        lat: branch.location ? branch.location.lat === 0 ? 51.1605 : branch.location.lat : 51.1605,//by default just set Astana
        lon: branch.location ? branch.location.long === 0 ? 71.4704 : branch.location.long : 71.4704,//by default just set Astana
        //set Whatsapp details
        whatsappPhoneNumber: branch.configuration.whatsapp_provider && branch.configuration.whatsapp_provider.enabled ? branch.configuration.whatsapp_provider.login : '',
        whatsappProvider: branch.configuration.whatsapp_provider && branch.configuration.whatsapp_provider.enabled ? branch.configuration.whatsapp_provider.provider : '',
        whatsappEnabledDefault: branch.configuration.whatsapp_provider ? branch.configuration.whatsapp_provider.enabled : false,
        whatsappEnabled: branch.configuration.whatsapp_provider ? branch.configuration.whatsapp_provider.enabled : false,
        isBeta: branch.configuration.whatsapp_provider ? branch.configuration.whatsapp_provider.is_beta : false,
        isUsedForAllGroups: branch.configuration.whatsapp_provider ? branch.configuration.whatsapp_provider.use_for_group : false,
        reportPhoneNumbers: branch.configuration.whatsapp_provider.report_phone_numbers ? branch.configuration.whatsapp_provider.report_phone_numbers : [],
        whatsappUrl: branch.configuration.whatsapp_provider ? branch.configuration.whatsapp_provider.url : "",
        whatsappCommercialText: branch.configuration.whatsapp_provider ? branch.configuration.whatsapp_provider.commercial_text : "",
        whatsappMailingLimit: branch.configuration.whatsapp_provider ? branch.configuration.whatsapp_provider.limit : 0,
        limit: branch.configuration.whatsapp_provider ? branch.configuration.whatsapp_provider.limit : 0,
        whatsappHeaderText: branch.configuration.whatsapp_header ? branch.configuration.whatsapp_header.text : "",
        whatsappHeaderTextEnabled: branch.configuration.whatsapp_header ? branch.configuration.whatsapp_header.enabled : false,
        //set QR code details
        qr: branch.qr ? branch.qr : '',
        directAvailable: branch.configuration.direct ? branch.configuration.direct.enabled : false,
        extra_fields: branch.configuration.direct ? branch.configuration.direct.extra_fields ? branch.configuration.direct.extra_fields : [] : [],
        directCurrentCustomFieldText: branch.configuration.direct ? branch.configuration.direct.custom_text_direct : "",
        direct_custom_field: branch.configuration.direct ? branch.configuration.direct.custom_text_direct : "",
        //set MenuPanel details
        menuAvailable: branch.configuration.online ? branch.configuration.online.enabled : false,
        onlineCurrentCustomFieldText: branch.configuration.online ? branch.configuration.online.custom_text_online : "",
        online_custom_field: branch.configuration.online ? branch.configuration.online.custom_text_online : "",
        delivery: branch.configuration.online ? branch.configuration.online.delivery : null,
        pickup: branch.configuration.online ? branch.configuration.online.pickup : null,
        //set Certificate details
        certificateAvailable: branch.configuration.certificate ? branch.configuration.certificate.enabled : false,
        certificateExpDate: _.has(branch.configuration, 'certificate') ? branch.configuration.certificate.expiry_date : '',
        //set Certificate details
        securityAvailable: branch.configuration.security ? branch.configuration.security.enabled : false,
        //set Corporate cashback details
        corporateCashbackAvailable: _.has(branch.configuration, 'corporate_cashback') ? branch.configuration.corporate_cashback.enabled : false,
        cashbackIsSet: _.has(branch.rule, 'corporate_cashback_percentage') ? branch.rule.corporate_cashback_percentage !== 0 ? true : false : false,
        cashback: _.has(branch.rule, 'corporate_cashback_percentage') ? branch.rule.corporate_cashback_percentage : 0,
        share_url: _.has(branch.configuration, 'corporate_cashback') ? branch.configuration.corporate_cashback.share_url : '',
        //set Gradation cashback details
        gradationAvailable: _.has(branch.configuration, 'gradation_cashback') ? branch.configuration.gradation_cashback.enabled : false,
        gradationCashbackList: _.has(branch.rule, 'gradation_cashbacks') ? branch.rule.gradation_cashbacks : [],
        //set Cross marketing details
        crossMarketingAvailable: _.has(branch.configuration, 'cross_marketing') ? branch.configuration.cross_marketing.enabled : false,
        //set Elevated cashback details
        elevatedCashbackAvailable: _.has(branch.configuration, 'first_cashback') ? branch.configuration.first_cashback.enabled : false,
        firstPurchase: _.has(branch.rule, 'first_cashback_percentage') ? branch.rule.first_cashback_percentage > 0 ? branch.rule.first_cashback_percentage : '' : '',
        //set Private cashback details
        privateCashbackAvailable: _.has(branch.configuration, 'private_cashback') ? branch.configuration.private_cashback.enabled : false,
        whatsappCodeAvailable: _.has(branch.configuration, 'private_cashback') ? branch.configuration.private_cashback.enable_send_code_to_customer : false,
        //set cash Payment details
        cashPaymentAvailable: _.has(branch.configuration, 'pay_from_cash') ? branch.configuration.pay_from_cash.enabled : false,
        cashExtraCommission: _.has(branch.rule, 'extra_commission_percentage') ? branch.rule.extra_commission_percentage > 0 ? branch.rule.extra_commission_percentage : '' : '',
        //set auto Payment details
        autoPaymentEnabled: _.has(branch.configuration, 'auto_payment') ? branch.configuration.auto_payment.enabled : false,
        autoPaymentInterval: _.has(branch.configuration, 'auto_payment') ? branch.configuration.auto_payment.expiry_date : '',
        //set SMS details
        smsEnabled: _.has(branch.configuration, 'sms') ? branch.configuration.sms.enabled : false,
        smsLimit: _.has(branch.configuration, 'sms') ? branch.configuration.sms.limit : '',
        //additional services
        serviceList: branch.additionally ? branch.additionally : [],
        groupId: branch.group_id,
        //Subscription
        created_at: branch.created_at,
        started_at: branch.subscription ? branch.subscription.started_at : 0,
        expired_at: branch.subscription ? branch.subscription.expired_at : 0,
        docs_received_at: branch.subscription ? branch.subscription.docs_received_at : 0,
        integrated_at: branch.subscription ? branch.subscription.integrated_at : 0,
        trained_at: branch.subscription ? branch.subscription.trained_at : 0,
        contract_renewed_at: branch.subscription ? branch.subscription.contract_renewed_at : 0,
        contract_expires_at: branch.subscription ? branch.subscription.contract_expires_at : 0,
        manager_name: branch.subscription ? branch.subscription.manager_name : '',
        manager_phone: branch.subscription ? branch.subscription.manager_phone : '',
        contract_amount: branch.subscription ? branch.subscription.contract_amount : '',
        contract_number: branch.subscription ? branch.subscription.contract_number : '',
        business_phone: branch.subscription ? branch.subscription.business_phone : '',
        delay_days: branch.subscription ? branch.subscription.delay_days : '',
        //set Voucher
        voucherAvailable: _.has(branch.configuration, 'voucher') ? branch.configuration.voucher.enabled : false,
        //Ambassador
        ambassador: branch.ambassador ? branch.ambassador : null
      }, function () {
        if (this.state.serviceList.length > 0) {
          let serviceIdList = [];
          this.state.serviceList.forEach(service => {
            serviceIdList.push(service.id);
          });
          this.setState({ serviceIdList, helperServiceIdList: serviceIdList });
        }
        const coor = {
          lat: this.state.lat,
          lng: this.state.lon
        }
        this.props.setCoordinates(coor);
      });
    }

    if (prevProps.categoryList !== this.props.categoryList) {
      const { categoryList } = this.props;
      let categoryListOptions = [];
      categoryList.forEach(element => {
        const obj = {
          key: element.id,
          value: element.id,
          text: element.name
        }
        categoryListOptions.push(obj);
      });
      this.setState({ categoryListOptions });
    }
  }

  componentWillUnmount() {
    //need to empty the array from reducer when leaving the component
    this.props.emptyServiceList();
  }

  //update states via ChildComponents
  updateState = (name, value) => {
    this.setState({ [name]: value });
  }

  onChange(e) {
    const { name, value } = e.target;
    // console.log(name, value);
    const { workHourList } = this.state; // phoneList
    if (name.startsWith('workHourStart') || name.startsWith('workHourEnd')) {
      const workHourPattern = new RegExp(/^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/);
      let startWorkHourDay = 0;
      let endWorkHourDay = 0;
      for (let i = 1; i <= 7; i++) {
        if (name.endsWith(i.toString())) {
          startWorkHourDay = name.startsWith('workHourStart') ? i : 0;
          endWorkHourDay = name.startsWith('workHourEnd') ? i : 0;
        }
      }
      const index = name.startsWith('workHourStart') ? workHourList.findIndex(x => x.day === startWorkHourDay) : workHourList.findIndex(x => x.day === endWorkHourDay);
      const workHour = name.startsWith('workHourStart') ? workHourList.find(x => x.day === startWorkHourDay) : workHourList.find(x => x.day === endWorkHourDay);
      const newObj = {
        day: workHour.day,
        start: name.startsWith('workHourStart') ? value : workHour.start,
        end: name.startsWith('workHourEnd') ? value : workHour.end,
        allday: false,
        dayoff: false
      };
      if (workHour.hasOwnProperty('lunch'))
        newObj.lunch = workHour.lunch;
      // console.log(newObj);
      this.setState({
        workHourList: [...workHourList.slice(0, index), newObj, ...workHourList.slice(index + 1)]
      }, function () {
        if (workHourPattern.test(value) || value === '') {
          this.setState({
            startIsValid: name.startsWith('workHourStart') ? true : this.state.startIsValid,
            endIsValid: name.startsWith('workHourEnd') ? true : this.state.endIsValid,
            startWorkHourDay,
            endWorkHourDay
          });
        }
        else {
          this.setState({
            startIsValid: name.startsWith('workHourStart') ? false : this.state.startIsValid,
            endIsValid: name.startsWith('workHourEnd') ? false : this.state.endIsValid,
            startWorkHourDay,
            endWorkHourDay
          });
        }
      })
    }
    else if (name.startsWith('lunchHourStart') || name.startsWith('lunchHourEnd')) {
      const lunchHourPattern = new RegExp(/^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/);
      let startLunchHourDay = 0;
      let endLunchHourDay = 0;
      for (let i = 1; i <= 7; i++) {
        if (name.endsWith(i.toString())) {
          startLunchHourDay = name.startsWith('lunchHourStart') ? i : 0;
          endLunchHourDay = name.startsWith('lunchHourEnd') ? i : 0;
        }
      }
      const index = name.startsWith('lunchHourStart') ? workHourList.findIndex(x => x.day === startLunchHourDay) : workHourList.findIndex(x => x.day === endLunchHourDay);
      const workHour = name.startsWith('lunchHourStart') ? workHourList.find(x => x.day === startLunchHourDay) : workHourList.find(x => x.day === endLunchHourDay);
      const lunchObj = {
        start: name.startsWith('lunchHourStart') ? value : workHour.lunch ? workHour.lunch.start : '',
        end: name.startsWith('lunchHourEnd') ? value : workHour.lunch ? workHour.lunch.end : ''
      }
      const newObj = {
        day: workHour.day,
        start: workHour.start,
        end: workHour.end,
        allday: workHour.allday,
        dayoff: false,
        lunch: lunchObj
      };
      this.setState({
        workHourList: [...workHourList.slice(0, index), newObj, ...workHourList.slice(index + 1)]
      }, function () {
        if (lunchHourPattern.test(value) || value === '') {
          this.setState({
            lunchStartIsValid: name.startsWith('lunchHourStart') ? true : this.state.lunchStartIsValid,
            lunchEndIsValid: name.startsWith('lunchHourEnd') ? true : this.state.lunchEndIsValid,
            startLunchHourDay,
            endLunchHourDay
          });
        }
        else {
          this.setState({
            lunchStartIsValid: name.startsWith('lunchHourStart') ? false : this.state.lunchStartIsValid,
            lunchEndIsValid: name.startsWith('lunchHourEnd') ? false : this.state.lunchEndIsValid,
            startLunchHourDay,
            endLunchHourDay
          });
        }
      }
      )
    }
    else if (name === 'network_url') {
      let startUrl = 'https://';
      if (value !== 'https:/')
        this.setState({ [name]: value.startsWith('https') ? value : startUrl + value });
    }
    else {
      this.setState({
        [name]: value
      }, () => {
        // const phonePattern = new RegExp(/^((\+7)+([0-9]){10}|(\8)+([0-9]){9})$/);
        // if(name === 'phone'){
        //     if(phonePattern.test(value)){
        //         this.setState({phoneIsValid: true, phoneOrder: 0});
        //     }
        //     else{
        //         this.setState({phoneIsValid: false, phoneOrder: 0});
        //     }
        // }
        // else if(name === 'phone1' || name === 'phone2' || name === 'phone3'){
        //     let phoneOrder = 0;
        //     for(let i = 1; i <= 3; i++){
        //         if(name.endsWith(i.toString())){
        //             phoneOrder = i;
        //         }
        //     }
        //     if(phonePattern.test(value)){
        //         this.setState({phoneIsValid: true, phoneOrder}, function(){
        //             this.setState({phoneList: [...phoneList.slice(0, phoneOrder - 1), value, ...phoneList.slice(phoneOrder)]});
        //         });
        //     }
        //     else{
        //         this.setState({phoneIsValid: false, phoneOrder});
        //     }
        // }
      });
    }
  }

  onSelectChange(e, data) {
    const { name, value } = data;
    // console.log(name, value);
    if (name === 'serviceIdList') {
      const { helperServiceIdList } = this.state;
      const serviceIdList = helperServiceIdList.concat(value);
      this.setState({ serviceIdList });
    }
    else if (name === 'categoryId') {
      const subCategList = this.props.subCategoryList.filter(x => x.parent_id === value);
      let subCategOptions = [];
      subCategList[0].list.forEach(el => {
        const obj = {
          key: el.id,
          value: el.id,
          text: el.name
        };
        subCategOptions.push(obj);
      });
      this.setState({ subCategOptions });
    }
    else if (name === 'subcategory') {
      this.setState({
        subCategIdList: value
      });
    }
    this.setState({
      [name]: value
    });
  }

  onFileChange = (e) => {
    // console.log(e.target.files[0]);
    this.setState({ file: e.target.files[0] });
  }

  onCheckboxChange(e, data) {
    // console.log(data);
    const name = data.name;
    const value = data.checked;

    const { workHourList } = this.state;
    if (name.startsWith('allday') || name.startsWith('dayoff')) {
      let allday = 0;
      let dayoff = 0;
      for (let i = 1; i <= 7; i++) {
        if (name.endsWith(i.toString())) {
          allday = name.startsWith('allday') ? i : 0;
          dayoff = name.startsWith('dayoff') ? i : 0;
        }
      }
      const index = name.startsWith('allday') ? workHourList.findIndex(x => x.day === allday) : workHourList.findIndex(x => x.day === dayoff);
      const workHour = name.startsWith('allday') ? workHourList.find(x => x.day === allday) : workHourList.find(x => x.day === dayoff);
      const newObj = {
        day: workHour.day,
        start: name.startsWith('dayoff') && value ? '' : name.startsWith('allday') && value ? '' : workHour.start,
        end: name.startsWith('dayoff') && value ? '' : name.startsWith('allday') && value ? '' : workHour.end,
        allday: name.startsWith('allday') ? value : !value,
        dayoff: name.startsWith('dayoff') ? value : !value
      };
      if (workHour.hasOwnProperty('lunch'))
        newObj.lunch = workHour.lunch;
      this.setState({
        workHourList: [...workHourList.slice(0, index), newObj, ...workHourList.slice(index + 1)]
      })
    }
  }

  //activates/deactivates the branch
  activateDeactivateBranch(enabled) {
    const { branchId } = this.props.match.params;

    this.props.updateBranchDetails('/api/v1/branches/' + branchId + '/activate', { enabled })
      .then(function (res) {
        if (res.status === 200) this.setState({ enabled });
      }.bind(this), function (error) {
        if (error.data.code === 4004 || error.data.code === 4005) {
          this.setState({
            positive: false,
            hidden: false,
            messageTitle: 'Not activated.',
            messageBody: error.data.message
          })

          setTimeout(() => {
            this.setState({ hidden: true })
          }, 5000)
        }
      }.bind(this))
  }

  //remove branch icon
  deleteBranchIcon() {
    const { branchId } = this.props.match.params;
    this.props.handleRemove('/api/v1/branches/' + branchId + '/icon')
      .then(function (res) {
        if (res.status === 200) {
          this.setState({
            branchIcon: ''
          });
        }
      }.bind(this));
  }

  //upload branch icon
  uploadBranchIcon() {
    const { branchId } = this.props.match.params;
    const { file } = this.state;
    let data = new FormData();
    data.append('icon', file);
    this.props.updateBranchDetails('/api/v1/branches/' + branchId + '/icon', data)
      .then(function (res) {
        if (res.status === 200) {
          this.setState({
            iconModalOpen: false,
            file: {}
          }, function () {
            const { branchId } = this.props.match.params;
            this.props.getBranchDetails('/api/v1/branches/' + branchId + '/full');
          });
        }
      }.bind(this));
  }

  //for toggling edit mode
  toggleEdit = () => {
    //when cancel is pressed reset the values
    if (this.state.editIsOn) {
      this.setState({
        address: this.props.branch.address,
        phoneList: this.props.branch.phones,
        workHourList: this.props.branch.working_hours,
        phoneOrder: 0,
        phoneIsValid: true,
        startWorkHourDay: 0,
        endWorkHourDay: 0,
        startIsValid: true,
        endIsValid: true,
        editIsOn: !this.state.editIsOn
      });
    }
    else {
      this.setState({ editIsOn: !this.state.editIsOn });
    }
  }
  //for toggling the Modal
  updateDetailsToggle = () => this.setState({ editDetailsOn: !this.state.editDetailsOn }, function () {
    //when cancel is pressed reset values
    if (!this.state.editDetailsOn) {
      this.setState({
        name: this.props.branch.name,
        description: this.props.branch.description
      });
    }
  });
  handleModalToggle = () => this.setState({ socialNetModalOpen: !this.state.socialNetModalOpen });
  handleIconModalToggle = () => this.setState({ iconModalOpen: !this.state.iconModalOpen }, function () {
    //when cancel is pressed set file to an empty object
    if (!this.state.iconModalOpen)
      this.setState({ file: {} });
  })
  handleBannerModalToggle = () => this.setState({ bannerModalOpen: !this.state.bannerModalOpen }, function () {
    //when cancel is pressed set file to an empty object
    if (!this.state.bannerModalOpen)
      this.setState({ file: {} });
  })
  handleServiceModalToggle = () => this.setState({ serviceModalOpen: !this.state.serviceModalOpen });
  //for toggling website edit mode
  toggleSiteEdit = () => this.setState({ siteEditIsOn: !this.state.siteEditIsOn });
  //for Dimmer in BannerData Component
  handleDimmerToggle = (id) => this.setState({ banner_id: id });

  //updates the name and description of the branch
  updateNameDescription = () => {
    const { branchId } = this.props.match.params;
    const { name, description } = this.state;
    const data = { name, description };

    this.props.updateBranchDetails('/api/v1/branches/' + branchId + '/description', data)
      .then(function (res) {
        if (res.status === 200) {
          this.setState({
            editDetailsOn: false
          });
        }
      }.bind(this), function (error) {
        if (error.data.code === 4012) {
          this.setState({
            positive: false,
            hidden: false,
            messageTitle: 'Branch name is too long.',
            messageBody: error.data.message
          })

          setTimeout(() => {
            this.setState({ hidden: true })
          }, 4000)
        }
      }.bind(this))
  }

  //updates contract details of the branch
  updateContract() {
    const { branchId } = this.props.match.params;
    const { legal_name, commission_percentage,
      cashback_percentage, extra_commission_percentage,
      certificate_cashback_percentage, order_min_amount,
      order_max_amount, bank_name, bin, bik, iik } = this.state;
    const data = {
      legal_name,
      commission_percentage: Number(commission_percentage),
      cashback_percentage: Number(cashback_percentage),
      extra_commission_percentage: Number(extra_commission_percentage),
      certificate_cashback_percentage: Number(certificate_cashback_percentage),
      order_min_amount: Number(order_min_amount),
      order_max_amount: Number(order_max_amount),
      bank_name, bin, bik, iik
    }

    this.props.updateBranchDetails('/api/v1/branches/' + branchId + '/contract', data)
      .then(function (res) {
        if (res.status === 200) {
          this.setState({
            rule: { ...data, bank: bank_name },
            editIsOn: false
          });
        }
      }.bind(this));
  }

  //removes banner by id
  deleteBanner(id) {
    const { branchId } = this.props.match.params;
    const { bannerList } = this.state;
    this.props.handleRemove('/api/v1/branches/' + branchId + '/banners/' + id)
      .then(function (res) {
        if (res.status === 200) {
          this.setState({
            bannerList: bannerList.filter(banner => banner.id !== id)
          });
        }
      }.bind(this));
  }

  //uploads banner
  addBanner() {
    const { branchId } = this.props.match.params;
    const { bannerList, file } = this.state;
    let data = new FormData();
    data.append('banner', file);
    this.props.updateBranchDetails('/api/v1/branches/' + branchId + '/banners', data)
      .then(function (res) {
        if (res.status === 200) {
          const uploadedFile = {
            id: res.data.data.id,
            url: res.data.data.url,
            mime: res.data.data.mime
          }
          this.setState({
            bannerList: [...bannerList, uploadedFile],
            bannerModalOpen: false,
            file: {}
          });
        }
      }.bind(this));
  }

  //update website
  updateWebsite() {
    const { branchId } = this.props.match.params;
    const data = {
      website: this.state.website,
      social_networks: this.state.socialNetList
    }

    this.props.updateBranchDetails('/api/v1/branches/' + branchId + '/social', data)
      .then(function (res) {
        if (res.status === 200) {
          this.setState({
            siteEditIsOn: false
          });
        }
      }.bind(this));
  }

  //editing or adding social net
  editOrAddNet(editOrAdd, network_type, network_url, network_index) {
    this.setState({
      editOrAdd,
      network_type,
      network_url,
      network_index
    }, function () {
      this.setState({ socialNetModalOpen: true });
    });
  }

  //adds social network to the state then sends it to the server
  addSocialNet() {
    const { branchId } = this.props.match.params;
    const { socialNetList, network_index } = this.state;
    const new_network = {
      type: this.state.network_type,
      url: this.state.network_url
    }

    let list = [...socialNetList];
    if (list.find(x => x.type === new_network.type)) {
      let index = list.findIndex(x => x.type === new_network.type);
      list[index] = new_network;
    } else {
      list.push(new_network);
    }
    const data = {
      website: this.state.website,
      social_networks: list
    }
    this.props.updateBranchDetails('/api/v1/branches/' + branchId + '/social', data)
      .then(res => {
        if (res.status === 200) {
          this.setState({
            socialNetList: [...socialNetList.slice(0, network_index), new_network, ...socialNetList.slice(network_index + 1)],
            socialNetModalOpen: false
          });
        }
      });
  }

  //removes social network from the state then sends it to the server
  removeSocialNet() {
    const { branchId } = this.props.match.params;
    const { socialNetList, network_index } = this.state;
    this.setState({
      socialNetList: socialNetList.filter((_, i) => i !== network_index)
    }, function () {
      const data = {
        website: this.state.website,
        social_networks: this.state.socialNetList
      }
      this.props.updateBranchDetails('/api/v1/branches/' + branchId + '/social', data)
        .then(function (res) {
          if (res.status === 200) {
            this.setState({
              socialNetModalOpen: false
            });
          }
        }.bind(this));
    })
  }

  //add phone to the state list
  addPhone() {
    const { phoneList, phone } = this.state;
    // console.log(phone);
    const index = phoneList.length;
    if (phoneList.length < 3 && this.state.phoneIsValid) {
      this.setState({ phoneList: [...phoneList.slice(0, index), phone, ...phoneList.slice(index + 1)] }, function () {
        const num = this.state.phoneList.length;
        switch (num) {
          case 1: this.setState({ phone1: this.state.phone }, function () { this.setState({ phone: '' }); });
            break;
          case 2: this.setState({ phone2: this.state.phone }, function () { this.setState({ phone: '' }); });
            break;
          case 3: this.setState({ phone3: this.state.phone }, function () { this.setState({ phone: '' }); });
            break;
          default: this.setState({ phone4: this.state.phone }, function () { this.setState({ phone: '' }); });
        }
      });
    }
    else
      return;
  }

  //remove phone from the state list
  deletePhone(index) {
    this.setState({
      phoneList: this.state.phoneList.filter((_, i) => i !== index)
    });
  }

  //update address(with coordinates), phones and working hours all together
  updateAddressPhoneWorkHour() {
    const { branchId } = this.props.match.params;
    const data = {
      address: this.state.address,
      latitude: this.props.coordinates.lat,
      longitude: this.props.coordinates.lng,
      phones: this.state.phoneList,
      working_hours: this.state.workHourList
    }
    let { branch } = this.props;
    branch.address = this.state.address;
    branch.phones = this.state.phoneList;
    branch.working_hours = this.state.workHourList;
    if (branch.location.hasOwnProperty('lat'))
      branch.location.lat = this.props.coordinates.lat;
    if (branch.location.hasOwnProperty('long'))
      branch.location.long = this.props.coordinates.lng;

    this.props.updateBranchDetails('/api/v1/branches/' + branchId + '/address', data)
      .then(function (res) {
        if (res.status === 200) {
          this.props.getBranchDetailSuccess(branch);
          this.setState({
            editIsOn: false
          });
        }
      }.bind(this));
  }

  //generate QR code
  generateQRcode() {
    const { branchId } = this.props.match.params;

    this.props.generateQRcode('/api/v1/branches/' + branchId + '/generateQR')
      .then(function (res) {
        if (res.status === 200) {
          this.setState({
            qr: res.data.data
          });
        }
      }.bind(this));
  }

  //toggle creact extra_field for Direct
  toggleCreateExtraField = () => { this.setState({ createDirectExtraFieldIsOn: !this.state.createDirectExtraFieldIsOn }) }

  //activate/deactivate direct (QR code)
  activateDeactivateDirect(status) {
    const { branchId } = this.props.match.params;
    const { configuration } = this.state.branch;

    let data = {
      "online": configuration.online,
      "direct": { "enabled": status },
      "certificate": configuration.certificate
    }

    this.props.updateBranchDetails('/api/v1/branches/' + branchId + '/configuration', data)
      .then(res => {
        if (res.status === 200) {
          this.setState({
            directAvailable: status
          });
        }
      });
  }

  //creating extra_field
  createExtraField = () => {
    const { branchId } = this.props.match.params;
    const data = {
      "hint_text": this.state.hint_text,
      "regex_type": this.state.regex_type ? this.state.regex_type : "",
      "key": this.state.keyword
    }

    this.props.addItem('/api/v1/branches/' + branchId + '/extra/field', data)
      .then(res => {
        if (res.status === 200) {
          // console.log(res);
          const { extra_fields } = res.data.data.configuration.direct;
          this.setState({
            hint_text: "", regex_type: "", keyword: "",
            createDirectExtraFieldIsOn: false,
            extra_fields
          });
        }
      });
  }

  //deleting extra_field
  deleteExtraField = (id) => {
    const { branchId } = this.props.match.params;

    this.props.deleteItem('/api/v1/branches/' + branchId + '/extra/' + id)
      .then(res => {
        if (res.data.message === "Success") {
          // console.log(res);
          const { extra_fields } = this.state;
          this.setState({
            extra_fields: [...extra_fields].filter(x => x.id !== id)
          });
        }
      });
  }

  //add custom field for Direct/Online
  addCustomField = (type) => {
    const { branchId } = this.props.match.params;
    const { direct_custom_field, online_custom_field } = this.state;
    let approve_text = "";
    switch (type) {
      case "DIRECT": approve_text = direct_custom_field; break;
      case "ONLINE": approve_text = online_custom_field; break;
      default: break;
    }
    const data = {
      "type": type,
      "approve_text": approve_text
    }

    this.props.addItem('/api/v1/branches/' + branchId + '/configuration/custom/text', data)
      .then(res => {
        if (res.status === 200) {
          if (type === "DIRECT") {
            this.setState({
              directCurrentCustomFieldText: data.approve_text,
              directCustomFieldEditIsOn: false
            });
          }
          else if (type === "ONLINE") {
            this.setState({
              onlineCurrentCustomFieldText: data.approve_text,
              onlineCustomFieldEditIsOn: false
            });
          }
        }
      });
  }

  //toggle edit custom field for Direct/Online
  toggleEditCustomField = (type) => {
    if (type === "direct") {
      this.setState({ directCustomFieldEditIsOn: !this.state.directCustomFieldEditIsOn })
    }
    else if (type === "online") {
      this.setState({ onlineCustomFieldEditIsOn: !this.state.onlineCustomFieldEditIsOn })
    }
  }

  //activate/deactivate menu
  activateDeactivateMenu(enabled) {
    const { branchId } = this.props.match.params;
    const { configuration } = this.state.branch;
    const data = { ...configuration, online: { enabled } };

    this.props.updateBranchDetails('/api/v1/branches/' + branchId + '/configuration', data)
      .then(res => {
        if (res.status === 200) {
          this.setState({ menuAvailable: enabled });
        }
      });
  }

  //activate/deactivate certificate
  activateDeactivateCertificate(enabled) {
    const { branchId } = this.props.match.params;
    const { configuration } = this.state.branch;
    const data = { ...configuration, certificate: { enabled } };

    this.props.updateBranchDetails('/api/v1/branches/' + branchId + '/configuration', data)
      .then(res => {
        if (res.status === 200) {
          this.setState({ certificateAvailable: enabled });
        }
      });
  }

  toggleEditCertificateExpDate = () => this.setState({ editCertificateExpDate: !this.state.editCertificateExpDate }, function () {
    if (!this.state.editCertificateExpDate) this.setState({ certificateExpDate: this.state.branch.configuration.certificate.expiry_date });
  });

  //update certificate's expire date
  updateCertificateExpDate = () => {
    const { branchId } = this.props.match.params;
    const certificate = {
      "enabled": true,
      "expiry_date": this.state.certificateExpDate
    }

    this.props.updateBranchDetails('/api/v1/branches/' + branchId + '/configuration/certificate/expiry-date', certificate)
      .then(function (res) {
        if (res.status === 200) {
          this.setState({
            editCertificateExpDate: false
          });
        }
      }.bind(this));
  }

  //activate/deactivate certificate
  activateDeactivateSecurity(status) {
    const { branchId } = this.props.match.params;
    const { configuration } = this.state.branch;

    let data = {
      ...configuration,
      security: {
        ...configuration.security,
        enabled: status
      }
    }

    this.props.updateBranchDetails('/api/v1/branches/' + branchId + '/configuration', data)
      .then(res => {
        if (res.status === 200) {
          this.setState({
            securityAvailable: status
          });
        }
      });
  }

  //activate/deactivate corporate cashback
  activateDeactivateCorporateCashback = (status) => {
    const { branchId } = this.props.match.params;

    this.props.updateBranchDetails('/api/v1/branches/' + branchId + '/contract/corporate/activate', { enabled: status })
      .then(function (res) {
        if (res.status === 200) {
          this.setState({
            corporateCashbackAvailable: status
          });
        }
      }.bind(this));
  }

  editCashbackToggle = () => this.setState({ editCashback: !this.state.editCashback }, function () {
    if (!this.state.editCashback) this.setState({ cashback: this.state.branch.rule.corporate_cashback_percentage });
  });

  //setting a new corporate cashback
  setCashback() {
    const { branchId } = this.props.match.params;
    const { cashback, branch: { configuration } } = this.state;
    let corporate_cashback = configuration.corporate_cashback ? configuration.corporate_cashback : {};
    corporate_cashback.enabled = true;
    corporate_cashback.new_cashback = Number(cashback);
    configuration.corporate_cashback = corporate_cashback;
    if (cashback === 0 || cashback === '') return;
    this.props.updateBranchDetails('/api/v1/branches/' + branchId + '/configuration', configuration)
      .then(function (res) {
        if (res.status === 200) {
          this.setState({
            corporateCashbackAvailable: true,
            editCashback: false,
            cashbackIsSet: true
          });
        }
      }.bind(this));
  }

  //activate/deactivate gradation cashback
  activateDeactivateGradation(status) {
    const { branchId } = this.props.match.params;
    const data = {
      enabled: status
    }

    this.props.updateBranchDetails('/api/v1/branches/' + branchId + '/gradation/activated', data)
      .then(function (res) {
        if (res.status === 200) {
          this.setState({
            gradationAvailable: status
          });
        }
      }.bind(this));
  }

  //activate/deactivate cross marketing
  activateDeactivateCrossMarketing(status) {
    const { branchId } = this.props.match.params;
    const data = {
      enabled: status
    }

    this.props.updateBranchDetails('/api/v1/branches/' + branchId + '/configuration/cross-marketing/activate', data)
      .then(function (res) {
        if (res.status === 200) {
          this.setState({
            crossMarketingAvailable: status
          });
        }
      }.bind(this));
  }

  //add extra gradation cashback
  addExtraGradationCashback() {
    const { branchId } = this.props.match.params;
    const { gradationCashbackList, newCashback, amount } = this.state;
    const data = {
      new_cashback: Number(newCashback),
      amount: Number(amount)
    }

    if (newCashback === '' || amount === '') return;
    this.props.updateBranchDetails('/api/v1/branches/' + branchId + '/gradation/cashback', data)
      .then(function (res) {
        if (res.status === 200) {
          const tempList = gradationCashbackList;
          tempList.push({ extra_cashback: newCashback, amount: amount });
          this.setState({
            gradationCashbackList: tempList,
            newCashback: '',
            amount: ''
          });
        }
      }.bind(this));
  }

  //remove service from list
  deleteExtraGradationCashback(id) {
    const { branchId } = this.props.match.params;

    this.props.removeObject('/api/v1/branches/' + branchId + '/gradation/' + id)
      .then(function (res) {
        if (res.status === 200) {
          // console.log(res);
          const { gradationCashbackList } = this.state;
          this.setState({
            gradationCashbackList: gradationCashbackList.filter(x => x.id !== id)
          });
        }
      }.bind(this), function (error) {
        if (error) {
          console.log(error);
        }
      })
  }

  //setting and activating elevated cashback for first purchase
  set_activateElevatedCashback() {
    const { branchId } = this.props.match.params;
    const { firstPurchase } = this.state;
    const data = {
      enabled: true,
      new_cashback: Number(firstPurchase)
    }
    if (firstPurchase === 0 || firstPurchase === '') return;
    this.props.updateBranchDetails('/api/v1/branches/' + branchId + '/first-purchase/activated', data)
      .then(function (res) {
        if (res.status === 200) {
          this.setState({
            elevatedCashbackAvailable: true
          });
        }
      }.bind(this));
  }

  //deactivate elevated cashback
  deactivateElevatedCashback() {
    const { branchId } = this.props.match.params;
    const data = {
      enabled: false
    }
    this.props.updateBranchDetails('/api/v1/branches/' + branchId + '/first-purchase/activated', data)
      .then(function (res) {
        if (res.status === 200) {
          this.setState({
            elevatedCashbackAvailable: false
          });
        }
      }.bind(this));
  }

  //activate/deactivate private cashback
  activateDeactivatePrivateCashback = (enabled) => {
    const { branchId } = this.props.match.params;
    this.props.updateBranchDetails('/api/v1/branches/' + branchId + '/configuration/private-cashback/activate', { enabled })
      .then(res => {
        if (res.status === 200) {
          this.setState({
            privateCashbackAvailable: enabled
          });
        }
      });
  }

  //activate/deactivate cash payment
  activateDeactivateCashPayment = (enabled) => {
    const { branchId } = this.props.match.params;
    this.props.updateBranchDetails('/api/v1/branches/' + branchId + '/configuration/pay-from-cash/activate', { enabled })
      .then(function (res) {
        if (res.status === 200) {
          this.setState({
            cashPaymentAvailable: enabled
          });
        }
      }.bind(this));
  }

  //top up Branch balance
  topUpBalance = () => {
    const { branchId } = this.props.match.params;
    const { balance } = this.state;
    const data = { amount: Number(balance) };
    this.props.updateBranchDetails('/api/v1/branches/' + branchId + '/cash/balance', data)
      .then(res => {
        if (res.status === 200) {
          // console.log(res);
          this.setState({
            balance: '',
            topUpMsgVisible: true
          });

          setTimeout(() => {
            this.setState({ topUpMsgVisible: false })
          }, 3000)
        }
      })
  }

  //add services to the branch
  addServices() {
    const { branchId } = this.props.match.params;
    const { serviceIdList } = this.state;
    const data = {
      extra_id: serviceIdList
    };

    this.props.updateBranchDetails('/api/v1/branches/' + branchId + '/additionally/added', data)
      .then(function (res) {
        if (res.status === 200) {
          // console.log(res);
          this.setState({
            serviceList: res.data.data.additionally,
            serviceModalOpen: false
          });
        }
      }.bind(this), function (error) {
        if (error) {
          this.setState({
            serviceModalOpen: false
          })
        }
      }.bind(this))
  }

  //remove service from list
  deleteService(serviceId) {
    const { branchId } = this.props.match.params;

    this.props.removeObject('/api/v1/branches/' + branchId + '/additionally/' + serviceId)
      .then(function (res) {
        if (res.status === 200) {
          // console.log(res);
          const { serviceList } = this.state;
          this.setState({
            serviceList: serviceList.filter(x => x.id !== serviceId)
          });
        }
      }.bind(this), function (error) {
        if (error) {
          console.log(error);
        }
      })
  }

  editGroupIdToggle = () => this.setState({ editGroupId: !this.state.editGroupId }, function () {
    if (!this.state.editGroupId) this.setState({ groupId: this.state.branch.group_id });
  });

  //updating a groupId
  updateGroupId() {
    const { branchId } = this.props.match.params;
    const data = { group_id: this.state.groupId };
    this.props.updateBranchDetails('/api/v1/branches/' + branchId + '/grouping', data)
      .then(function (res) {
        if (res.status === 200) {
          // console.log(res);
          this.setState({
            editGroupId: false
          });
        }
      }.bind(this), function (error) {
        if (error) {
          alert("Input data is invalid");
        }
      })
  }

  editCategoryToggle = () => this.setState({ editCategory: !this.state.editCategory }, function () {
    if (!this.state.editCategory) {
      let categories = [];
      this.state.branch.categories.forEach(category => {
        const foundCategory = this.props.allcategoryList.find(x => x.id === category);
        if (foundCategory)
          categories.push({ id: foundCategory.id, name: foundCategory.name, parentId: foundCategory.parent_id });
      });
      this.setState({ categories });
    }
  });

  //update branch category
  updateBranchCategory() {
    const { branchId } = this.props.match.params;
    let category_ids = [];
    category_ids.push(this.state.categoryId);
    category_ids = category_ids.concat(this.state.subCategIdList);
    const data = { categories: category_ids };
    let categories = [];
    category_ids.forEach(category => {
      const foundCategory = this.props.allcategoryList.find(x => x.id === category);
      if (foundCategory) categories.push({ id: foundCategory.id, name: foundCategory.name, parentId: foundCategory.parent_id });
    });

    this.props.updateBranchDetails('/api/v1/branches/' + branchId + '/categories', data)
      .then(function (res) {
        if (res.status === 200) {
          // console.log(res);
          this.setState({
            editCategory: false,
            categories
          });
        }
      }.bind(this), function (error) {
        if (error) {
          alert(error);
        }
      })
  }

  //activate/deactivate Auto Payment
  activateDeactivateAutoPayment = (enabled) => {
    const { branchId } = this.props.match.params;
    const data = {
      enabled,
      expired_date: this.state.autoPaymentInterval
    }
    this.props.updateBranchDetails('/api/v1/branches/' + branchId + '/auto-payment/activated', data)
      .then(res => {
        if (res.status === 200) {
          // console.log(res);
          this.setState({ autoPaymentEnabled: enabled });
        }
      })
  }

  //activate/deactivate SMS
  activateDeactivateSMS = (enabled) => {
    const { branchId } = this.props.match.params;
    const { smsLimit } = this.state;
    const data = {
      enabled,
      limit: Number(smsLimit)
    }
    this.props.updateBranchDetails('/api/v1/branches/' + branchId + '/configuration/sms-sending', data)
      .then(res => {
        if (res.status === 200) {
          // console.log(res);
          this.setState({ smsEnabled: enabled });
        }
      })
  }

  // Change Branch Config For Mailing
  activateMailing = (useForGroup) => {
    const { branchId } = this.props.match.params;
    const { whatsappPhoneNumber, whatsappProvider, whatsappCommercialText, reportPhoneNumbers, whatsappMailingLimit } = this.state;
    const data = {
      enabled: true,
      login: whatsappPhoneNumber,
      provider: whatsappProvider,
      commercial_text: whatsappCommercialText,
      use_for_group: useForGroup,
      report_phone_numbers: reportPhoneNumbers,
      limit: Number(whatsappMailingLimit)
    }
    this.props.updateBranchDetails('/api/v1/branches/' + branchId + '/configuration/mailing', data)
      .then(res => {
        if (res.status === 200) {
          this.setState({
            isUsedForAllGroups: useForGroup,
            whatsappEnabled: true,
            whatsappEnabledDefault: true,
            whatsappMsgHidden: false,
            whatsappMsgBody: "Данные успешно сохранены",
            limit: data.limit
          });

          setTimeout(() => {
            this.setState({ whatsappMsgHidden: true })
          }, 4000);
        }
      })
  }
  
  // Update limit For Mailing
  updateMailingLimit = () => {
    const { branchId } = this.props.match.params;
    const { whatsappMailingLimit } = this.state;
    const data = {
      limit: Number(whatsappMailingLimit)
    }
    this.props.updateBranchDetails(`/api/v1/branches/${branchId}/configuration/whatsapp-limit`, data)
      .then(res => {
        if (res.status === 200) {
          this.setState({
            whatsappMsgHidden: false,
            whatsappMsgBody: "Лимит успешно обновлен",
            limit: data.limit
          });

          setTimeout(() => {
            this.setState({ whatsappMsgHidden: true })
          }, 4000);
        }
      })
  }

  //Create Integrilla account for Mailing
  createIntegrillaAccount = () => {
    const { branchId } = this.props.match.params;
    const data = {};
    this.props.updateBranchDetails('/api/v1/branches/' + branchId + '/mailing/create-account', data)
      .then(res => {
        if (res.status === 200) {
          this.setState({
            whatsappUrl: res.data.data
          });
        }
      })
  }

  //Get Integrilla Account info
  getIntegrillaAccountInfo = () => {
    const { branchId } = this.props.match.params;
    this.props.handleGetMethod('/api/v1/branches/' + branchId + '/mailing/get-info')
      .then(res => {
        if (res.status === 200) {
          // console.log(res);
          this.setState({
            whatsappMessage: JSON.stringify(res.data.data),
            whatsappModalOpen: true
          });
        }
      })
  }

  //activate/deactivate whatsapp code
  activateDeactivateWhatsappCode = (enabled) => {
    const { branchId } = this.props.match.params;
    this.props.updateBranchDetails('/api/v1/branches/' + branchId + '/configuration/private-cashback/send-code-to-customer/enable', { enabled })
      .then(res => {
        if (res.status === 200) {
          this.setState({
            whatsappCodeAvailable: enabled
          });
        }
      });
  }

  toggleWhatsappEnable = (enable) => {
    this.setState({ whatsappEnabled: enable });
  }

  toggleDeleteWhatsappAccount = (open) => {
    this.setState({ deleteConfirmOpen: open });
  }

  toggleRestartWhatsappAccount = (open) => {
    this.setState({ restartConfirmOpen: open });
  }

  toggleWhatsappModal = (open) => {
    if (!this.state.whatsappMessage) {
      this.getIntegrillaAccountInfo();
    } else {
      this.setState({ whatsappModalOpen: open });
    }
  }

  //Delete/Restart Integrilla Account
  deleteRestartWhatsappAccount = (action) => {
    const { branchId } = this.props.match.params;
    const data = null;
    this.props.updateBranchDetails('/api/v1/branches/' + branchId + `/mailing/${action}-account`, data)
      .then(res => {
        if (res.status === 200) {
          this.setState({
            whatsappEnabled: false,
            whatsappEnabledDefault: false,
            deleteConfirmOpen: false,
            restartConfirmOpen: false,
          })
        }
      })
  }

  //Update Commercial Text for Mailing
  updateCommercialText = () => {
    const { branchId } = this.props.match.params;
    const data = {
      commercial_text: this.state.whatsappCommercialText
    };
    this.props.updateBranchDetails('/api/v1/branches/' + branchId + '/configuration/mailing/commercial-text', data)
      .then(res => {
        if (res.status === 200) {
          this.setState({
            whatsappMsgHidden: false,
            whatsappMsgBody: "Данные успешно сохранены"
          });

          setTimeout(() => {
            this.setState({ whatsappMsgHidden: true })
          }, 4000);
        }
      })
  }

  //Set Beta version for whatsapp
  setBetaForBranch = (value) => {
    const { branchId } = this.props.match.params;
    this.props.updateBranchDetails('/api/v1/branches/' + branchId + '/mailing/set-beta', { value })
      .then(res => {
        if (res.status === 200) {
          this.setState({
            isBeta: value
          });
        }
      })
  }

  // Activate/Deactivate whatsapp header text
  activateDeactivateWhatsappHeader = (enabled) => {
    const { branchId } = this.props.match.params;
    const { whatsappHeaderText } = this.state;
    const data = {
      enabled,
      text: whatsappHeaderText,
    }
    this.props.updateBranchDetails('/api/v1/branches/' + branchId + '/configuration/whatsapp-header', data)
      .then(res => {
        if (res.status === 200) {
          this.setState({
            whatsappHeaderTextEnabled: enabled
          });
        }
      })
  }

  // Save Subscription data
  saveSubscriptionData = (data) => {
    const { branchId } = this.props.match.params;

    this.props.updateBranchDetails('/api/v1/branches/' + branchId + '/subscription', data)
      .then(res => {
        if (res.status === 200) {
          this.setState({
            created_at: data.created_at,
            started_at: data.started_at,
            expired_at: data.expired_at,
            docs_received_at: data.docs_received_at,
            integrated_at: data.integrated_at,
            trained_at: data.trained_at,
            contract_renewed_at: data.contract_renewed_at,
            contract_expires_at: data.contract_expires_at,
            manager_name: data.manager_name,
            manager_phone: data.manager_phone,
            contract_amount: data.contract_amount,
            contract_number: data.contract_number,
            business_phone: data.business_phone,
            delay_days: data.delay_days
          });
        }
      })
  }

  //activate/deactivate voucher
  activateDeactivateVoucher = (enabled) => {
    const { branchId } = this.props.match.params;
    this.props.updateBranchDetails(`/api/v1/branches/${branchId}/configuration/voucher/enabled`, { enabled })
      .then(res => {
        if (res.status === 200) {
          this.setState({
            voucherAvailable: enabled
          });
        }
      });
  }

  render() {
    const { branch, name, description, editDetailsOn, enabled, branchIcon, iconModalOpen } = this.state;
    const { goBack } = this.props.history;
    const panes = [
      {
        menuItem: 'Contract', render: () =>
          <Tab.Pane attached={false}>
            <ContractData
              parent='branch'
              {...this.state}
              toggleEdit={this.toggleEdit}
              onChange={this.onChange}
              updateContract={this.updateContract.bind(this)}
              editGroupIdToggle={this.editGroupIdToggle.bind(this)}
              updateGroupId={this.updateGroupId.bind(this)}
              editCategoryToggle={this.editCategoryToggle.bind(this)}
              onSelectChange={this.onSelectChange}
              updateBranchCategory={this.updateBranchCategory.bind(this)}
              set_activateElevatedCashback={this.set_activateElevatedCashback.bind(this)}
              deactivateElevatedCashback={this.deactivateElevatedCashback.bind(this)}
              addCustomField={this.addCustomField}
              toggleEditCustomField={this.toggleEditCustomField}
              activateDeactivateCorporateCashback={this.activateDeactivateCorporateCashback}
              activateDeactivatePrivateCashback={this.activateDeactivatePrivateCashback}
              activateDeactivateCashPayment={this.activateDeactivateCashPayment}
              topUpBalance={this.topUpBalance}
              activateDeactivateAutoPayment={this.activateDeactivateAutoPayment}
              activateDeactivateSMS={this.activateDeactivateSMS}
            />
          </Tab.Pane>
      },
      {
        menuItem: 'Banners', render: () =>
          <Tab.Pane attached={false}>
            <BannerData
              {...this.state}
              handleDimmerToggle={this.handleDimmerToggle}
              deleteBanner={this.deleteBanner.bind(this)}
              addBanner={this.addBanner.bind(this)}
              handleBannerModalToggle={this.handleBannerModalToggle}
              onFileChange={this.onFileChange}
            />
          </Tab.Pane>
      },
      {
        menuItem: 'Website / Social Networks', render: () =>
          <Tab.Pane attached={false}>
            <SocialNetData
              {...this.state}
              toggleEdit={this.toggleEdit}
              toggleSiteEdit={this.toggleSiteEdit}
              updateWebsite={this.updateWebsite.bind(this)}
              handleModalToggle={this.handleModalToggle}
              addSocialNet={this.addSocialNet.bind(this)}
              removeSocialNet={this.removeSocialNet.bind(this)}
              editOrAddNet={this.editOrAddNet.bind(this)}
              onChange={this.onChange}
              onSelectChange={this.onSelectChange}
            />
          </Tab.Pane>
      },
      {
        menuItem: 'Address / Contacts / Working hours', render: () =>
          <Tab.Pane attached={false}>
            <WorkHourData
              {...this.state}
              toggleEdit={this.toggleEdit}
              onChange={this.onChange}
              onCheckboxChange={this.onCheckboxChange}
              addPhone={this.addPhone.bind(this)}
              deletePhone={this.deletePhone.bind(this)}
              updateAddressPhoneWorkHour={this.updateAddressPhoneWorkHour.bind(this)}
            />
          </Tab.Pane>
      },
      {
        menuItem: 'WhatsApp', render: () =>
          <Tab.Pane attached={false}>
            <WhatsApp
              {...this.state}
              activateDeactivateWhatsappCode={this.activateDeactivateWhatsappCode}
              activateMailing={this.activateMailing}
              updateMailingLimit={this.updateMailingLimit}
              toggleWhatsappEnable={this.toggleWhatsappEnable}
              createIntegrillaAccount={this.createIntegrillaAccount}
              toggleWhatsappModal={this.toggleWhatsappModal}
              toggleDeleteWhatsappAccount={this.toggleDeleteWhatsappAccount}
              toggleRestartWhatsappAccount={this.toggleRestartWhatsappAccount}
              deleteRestartWhatsappAccount={this.deleteRestartWhatsappAccount}
              updateCommercialText={this.updateCommercialText}
              setBetaForBranch={this.setBetaForBranch}
              activateDeactivateWhatsappHeader={this.activateDeactivateWhatsappHeader}
              onChange={this.onChange}
              onSelectChange={this.onSelectChange}
            />
          </Tab.Pane>
      },
      {
        menuItem: 'QR code', render: () =>
          <Tab.Pane attached={false}>
            <QRcodeData
              {...this.state}
              generateQRcode={this.generateQRcode.bind(this)}
              activateDeactivateDirect={this.activateDeactivateDirect.bind(this)}
              onChange={this.onChange}
              toggleCreateExtraField={this.toggleCreateExtraField}
              createExtraField={this.createExtraField}
              deleteExtraField={this.deleteExtraField}
            />
          </Tab.Pane>
      },
      {
        menuItem: 'Menu', render: () =>
          <Tab.Pane attached={false}>
            <MenuPanel
              {...this.props}
              {...this.state}
              updateState={this.updateState}
              activateDeactivateMenu={this.activateDeactivateMenu.bind(this)}
            />
          </Tab.Pane>
      },
      {
        menuItem: 'Certificate', render: () =>
          <Tab.Pane attached={false}>
            <CertificateData
              {...this.props}
              {...this.state}
              activateDeactivateCertificate={this.activateDeactivateCertificate.bind(this)}
              toggleEditCertificateExpDate={this.toggleEditCertificateExpDate}
              onSelectChange={this.onSelectChange}
              updateCertificateExpDate={this.updateCertificateExpDate}
            />
          </Tab.Pane>
      },
      // {
      //     menuItem: 'Corp. cashback', render: () =>
      //         <Tab.Pane attached={false}>
      //             <CashbackData
      //                 match={this.props.match}
      //                 history={this.props.history}
      //                 cashbackIsSet={this.state.cashbackIsSet}
      //                 editCashback={this.state.editCashback}
      //                 cashback={this.state.cashback}
      //                 share_url={this.state.share_url}
      //                 onChange={this.onChange}
      //                 editCashbackToggle={this.editCashbackToggle.bind(this)}
      //                 setCashback={this.setCashback.bind(this)}
      //             />
      //         </Tab.Pane>
      // },
      {
        menuItem: 'Gradation cashback', render: () =>
          <Tab.Pane attached={false}>
            <GradationData
              match={this.props.match}
              history={this.props.history}
              gradationAvailable={this.state.gradationAvailable}
              gradationCashbackList={this.state.gradationCashbackList}
              newCashback={this.state.newCashback}
              amount={this.state.amount}
              onChange={this.onChange}
              activateDeactivateGradation={this.activateDeactivateGradation.bind(this)}
              addExtraGradationCashback={this.addExtraGradationCashback.bind(this)}
              deleteExtraGradationCashback={this.deleteExtraGradationCashback.bind(this)}
            />
          </Tab.Pane>
      },
      {
        menuItem: 'Cross Marketing', render: () =>
          <Tab.Pane attached={false}>
            <CrossMarketingData
              crossMarketingAvailable={this.state.crossMarketingAvailable}
              activateDeactivateCrossMarketing={this.activateDeactivateCrossMarketing.bind(this)}
              branchId={this.props.match.params.branchId}
            />
          </Tab.Pane>
      },
      {
        menuItem: 'Additional services', render: () =>
          <Tab.Pane attached={false}>
            <ServiceData
              serviceListOptions={this.state.serviceListOptions}
              serviceList={this.state.serviceList}
              serviceModalOpen={this.state.serviceModalOpen}
              serviceIdList={this.state.serviceIdList}
              onSelectChange={this.onSelectChange}
              handleServiceModalToggle={this.handleServiceModalToggle.bind(this)}
              addServices={this.addServices.bind(this)}
              deleteService={this.deleteService.bind(this)}
            />
          </Tab.Pane>
      },
      {
        menuItem: 'Ambassador', render: () =>
          <Tab.Pane attached={false}>
            <Ambassador
              ambassador={this.state.ambassador}
              branchId={this.props.match.params.branchId}
            />
          </Tab.Pane>
      },
      {
        menuItem: 'Security', render: () =>
          <Tab.Pane attached={false}>
            <SecurityData
              {...this.props}
              {...this.state}
              activateDeactivateSecurity={this.activateDeactivateSecurity.bind(this)}
            />
          </Tab.Pane>
      },
      {
        menuItem: 'Profile', render: () =>
          <Tab.Pane attached={false}>
            <ProfileData
              {...this.state}
              saveSubscriptionData={this.saveSubscriptionData}
            />
          </Tab.Pane>
      },
      {
        menuItem: 'Voucher', render: () =>
          <Tab.Pane attached={false}>
            <VoucherData
              voucherAvailable={this.state.voucherAvailable}
              activateDeactivateVoucher={this.activateDeactivateVoucher}
            />
          </Tab.Pane>
      }
    ];

    return (
      <Grid style={{ margin: '0' }}>
        <Grid.Row columns={1}>
          <Grid.Column style={{ marginBottom: '10px' }}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={2} verticalAlign='middle'>
                  <Button compact onClick={goBack}>Back</Button>
                </Grid.Column>
                <Grid.Column width={7} textAlign='right' verticalAlign='middle'>
                  {editDetailsOn ?
                    <Grid>
                      <Grid.Row columns={1}>
                        <Grid.Column textAlign='right'>
                          <Input
                            name='name'
                            value={name}
                            placeholder='branch name'
                            onChange={this.onChange}
                          />
                        </Grid.Column>
                        <Grid.Column textAlign='right' style={{ marginTop: '3px' }}>
                          <TextArea
                            name='description'
                            value={description}
                            placeholder='description'
                            onChange={this.onChange}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <Button.Group floated='right' size='tiny'>
                            <Button onClick={this.updateDetailsToggle} color='orange'>
                              Cancel
                            </Button>
                            <Button.Or text='|' />
                            <Button color='teal' onClick={this.updateNameDescription}>
                              Save
                            </Button>
                          </Button.Group>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid> :
                    <Popup
                      content='Изменить данные'
                      trigger={<div style={{ cursor: 'pointer' }} onClick={this.updateDetailsToggle}>
                        <Header as='h3'>{name}</Header>
                        <p>{description}</p>
                      </div>}
                      position='top center'
                    />
                  }
                  <Message
                    positive={this.state.positive}
                    hidden={this.state.hidden}
                    messageTitle={this.state.messageTitle}
                    messageBody={this.state.messageBody}
                  />
                </Grid.Column>
                <Grid.Column width={5} verticalAlign='middle'>
                  {branchIcon === '' ?
                    <Image
                      style={{ width: '5em', height: '5em', cursor: 'pointer' }}
                      avatar src={require('../../assets/img/no_image.png')}
                      onClick={this.handleIconModalToggle}
                    /> :
                    <Popup
                      content='Remove icon'
                      trigger={<Image
                        style={{ width: '4em', height: '4em', cursor: 'pointer' }}
                        avatar src={branch.icon}
                        onClick={this.deleteBranchIcon.bind(this)}
                      />}
                      inverted position='right center'
                      style={popupStyle}
                    />
                  }
                </Grid.Column>
                <Grid.Column width={2} verticalAlign='middle'>
                  <Button compact color={enabled ? 'red' : 'green'}
                    onClick={enabled ? this.activateDeactivateBranch.bind(this, false) :
                      this.activateDeactivateBranch.bind(this, true)}>
                    {enabled ? 'Deactivate' : 'Activate'}
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
          <Grid.Column>
            <Tab menu={{ color: 'teal', secondary: true }} className='branch_tab' panes={panes} />
          </Grid.Column>
        </Grid.Row>
        <Modal
          centered={false} size='mini'
          open={iconModalOpen}
          onClose={this.handleIconModalToggle}
        >
          <Modal.Content>
            <Grid>
              <Grid.Row columns={1}>
                <Grid.Column>
                  <Input
                    fluid type='file'
                    onChange={(e) => this.onFileChange(e)}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row width={16}>
                <Grid.Column>
                  <Button color='green' size='mini'
                    floated='right' onClick={this.uploadBranchIcon.bind(this)}>
                    <Icon name='upload' />
                    Upload
                  </Button>
                  <Button color='orange' size='mini' floated='right'
                    onClick={this.handleIconModalToggle}>
                    <Icon name='cancel' /> Cancel
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Content>
        </Modal>
        <Dimmer active={this.props.requestInProcess} inverted>
          <Loader inverted content='Loading' />
        </Dimmer>
      </Grid>
    );
  }
}

Branch.propTypes = {
  getBranchDetails: PropTypes.func,
  getBranchDetailSuccess: PropTypes.func,
  branch: PropTypes.object,
  requestInProcess: PropTypes.bool,
  updateBranchDetails: PropTypes.func,
  handleRemove: PropTypes.func,
  setCoordinates: PropTypes.func,
  coordinates: PropTypes.object,
  generateQRcode: PropTypes.func,
  getServiceList: PropTypes.func,
  removeObject: PropTypes.func,
  getCategoryList: PropTypes.func,
  categoryList: PropTypes.array,
  subCategoryList: PropTypes.array,
  allcategoryList: PropTypes.array,
  emptyServiceList: PropTypes.func,
  addItem: PropTypes.func,
  deleteItem: PropTypes.func,
  handleGetMethod: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBranchDetails: (url) => dispatch(getBranchDetails(url)),
    getBranchDetailSuccess: (branch) => dispatch(getBranchDetailSuccess(branch)),
    updateBranchDetails: (url, data) => dispatch(updateBranchDetails(url, data)),
    handleRemove: (url) => dispatch(handleRemove(url)),
    setCoordinates: (coor) => dispatch(setCoordinates(coor)),
    generateQRcode: (url) => dispatch(generateQRcode(url)),
    getServiceList: (url) => dispatch(getServiceList(url)),
    removeObject: (url) => dispatch(removeObject(url)),
    getCategoryList: (url) => dispatch(getCategoryList(url)),
    emptyServiceList: () => dispatch(emptyServiceList()),
    addItem: (url, data) => dispatch(addItem(url, data)),
    deleteItem: (url) => dispatch(deleteItem(url)),
    handleGetMethod: (url) => dispatch(handleGetMethod(url)),
  };
};

const mapStateToProps = (state) => {
  return {
    requestInProcess: state.requestInProcess,
    branch: state.branch,
    coordinates: state.coordinates,
    categoryList: state.categoryList,
    subCategoryList: state.subCategoryList,
    allcategoryList: state.allcategoryList
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Branch);